<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t("alertsConfigurationMsgs.manageEmailAlerts") }}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <b-table
                :fields="tableColumns"
                :items="allAlertsConfigurations"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                @row-clicked="openAlertsConfigurationModal"
                tbody-tr-class="clickable-item"
              >
                <template #cell(title)="data">
                  {{ $t(`emailAlertTypes.${data.item.abbreviation}`) }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                    :class="`border border-${
                      data.item.is_active ? 'success' : 'danger'
                    } text-${data.item.is_active ? 'success' : 'danger'}`"
                    variant="none"
                    v-b-popover.hover.top
                    :title="
                      $t(
                        `alertsConfigurationMsgs.configStatusDesc${
                          data.item.is_active ? 'Enabled' : 'Disabled'
                        }`
                      )
                    "
                  >
                    {{
                      $t(
                        `alertsConfigurationMsgs.configStatus${
                          data.item.is_active ? "Enabled" : "Disabled"
                        }`
                      )
                    }}
                  </b-badge>
                </template>

                <template #cell(recipientsCount)="data">{{
                  data.item.recipient_count
                }}</template>

                <template #cell(lastRun)="data">
                  <span v-if="data.item.last_run_at">{{
                    formatDateTime(data.item.last_run_at)
                  }}</span>
                  <span v-else>--</span>
                </template>

                <template #cell(scheduledAt)="data">
                  <span v-if="data.item.scheduled_at !== '* * * * *'">
                    {{ formatCrontab(data.item.scheduled_at) }}
                  </span>
                  <span v-else>--</span>
                </template>
              </b-table>

              <b-pagination
                v-if="totalAlertsConfigurations > pageSize"
                :value="currentPage"
                :total-rows="totalAlertsConfigurations"
                :per-page="pageSize"
                @change="onPageChange"
                align="right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <post-alerts-configuration-modal
      :showModal.sync="modals.showAlertsConfigurationModal"
      :alertsConfigurationId="modals.selectedConfigId"
      @close="hideAlertsConfigurationModal"
      @closeUpdate="hideAlertsConfigurationUpdateModal"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { DEFAULT_PAGE_SIZE } from "../../common/constants";
import { formatDateTime, formatDate, formatCrontab } from "../../common/utils";
import PostAlertsConfigurationModal from "./PostAlertsConfigurationModal";

export default {
  name: "AlertsConfigurationList",

  components: { PostAlertsConfigurationModal },

  data() {
    return {
      allAlertsConfigurations: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalAlertsConfigurations: 0,

      isLoading: false,

      modals: {
        selectedConfigId: null,
        showAlertsConfigurationModal: false,
      },
    };
  },

  methods: {
    ...mapActions(["getAlertsConfigurations"]),
    formatDateTime,
    formatDate,
    formatCrontab,

    async fetchAlertsConfigurations(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAlertsConfigurations({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...params,
      });
      this.allAlertsConfigurations = response.data.results;
      this.currentPage = pageNum;
      this.totalAlertsConfigurations = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchAlertsConfigurations(pageNum);
    },

    openAlertsConfigurationModal(confRow) {
      this.modals.selectedConfigId = confRow.id;
      this.modals.showAlertsConfigurationModal = true;
    },

    hideAlertsConfigurationModal() {
      this.modals.selectedConfigId = null;
      this.modals.showAlertsConfigurationModal = false;
    },

    hideAlertsConfigurationUpdateModal() {
      this.hideAlertsConfigurationModal();
      this.fetchAlertsConfigurations(this.currentPage);
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {
    this.fetchAlertsConfigurations();
  },

  computed: {
    tableColumns() {
      return [
        {
          key: "title",
          label: `${this.$t("alertsConfigurationMsgs.alert")}`,
        },
        { key: "status", label: this.$t("status") },
        {
          key: "recipientsCount",
          label: this.$t("alertsConfigurationMsgs.recipients"),
        },
        { key: "lastRun", label: this.$t("alertsConfigurationMsgs.lastRun") },
        {
          key: "scheduledAt",
          label: this.$t("alertsConfigurationMsgs.scheduledAt"),
        },
      ];
    },
  },
};
</script>
