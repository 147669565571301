<template>
  <div class="container-fluid">
    <b-modal
      ref="post-alerts-configuration-modal"
      size="lg"
      :title="$t('alertsConfigurationMsgs.configEmailAlert')"
      :no-close-on-backdrop="isRecipientsOpen"
      hide-footer
      centered
      static
      lazy
      tall
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12 justify-content-end d-flex">
                  <div
                    class="
                      custom-control
                      custom-switch
                      custom-switch-color
                      custom-control-inline
                    "
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input bg-success"
                      id="isConfigActive"
                      v-model="isEnabled"
                    />
                    <label class="custom-control-label" for="isConfigActive">
                      {{
                        $t(
                          `alertsConfigurationMsgs.${
                            isEnabled ? "isEnabled" : "isDisabled"
                          }`
                        )
                      }}
                    </label>
                  </div>
                </div>

                <div class="form-group col-md-6">
                  <label for="day">
                    {{ $t("alertsConfigurationMsgs.alertDay") }}
                  </label>
                  <b-form-select
                    id="day"
                    class="form-control form-control-b-select"
                    v-model="day"
                    :options="allDaysOptions"
                    required
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>{{
                        $t("day")
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>

                <div class="form-group col-md-6">
                  <label for="dateTime">
                    {{ $t("alertsConfigurationMsgs.alertTime") }}
                    <i
                      v-b-popover.hover.top="
                        $t('alertsConfigurationMsgs.timezoneInfo')
                      "
                      class="
                        fas
                        fa-info-circle fa-sm
                        text-secondary
                        clickable-item
                      "
                    />
                  </label>

                  <date-picker
                    id="dateTime"
                    class="form-control datepicker-custom"
                    type="time"
                    v-model="time"
                    :lang="lang"
                    format="H:m"
                    value-type="H:m"
                    :minute-step="5"
                    required
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="recipients">
                    {{ $t("alertsConfigurationMsgs.selectRecipients") }}
                  </label>

                  <v-select
                    id="recipients"
                    class="form-control v-select-custom"
                    label="email"
                    v-model="recipients"
                    :placeholder="
                      $t('alertsConfigurationMsgs.recipientsPlaceholder')
                    "
                    :options="allUsers"
                    :loading="areRecipientsLoading"
                    :reduce="(user) => user.id"
                    @open="isRecipientsOpen = !isRecipientsOpen"
                    @close="closeRecSelect()"
                    multiple
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>

                    <template slot="option" slot-scope="option"
                      >{{ option.first_name }} {{ option.last_name }}</template
                    >
                    <template slot="selected-option" slot-scope="option"
                      >{{ option.first_name }} {{ option.last_name }}</template
                    >
                  </v-select>
                </div>
              </div>
              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading || areRecipientsLoading"
                  style="min-width: 100px"
                >
                  <i
                    v-if="isLoading || areRecipientsLoading"
                    class="fas fa-circle-notch fa-spin"
                  />
                  <span v-else>
                    {{ $t("alertsConfigurationMsgs.set") }}
                  </span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en";
import "vue2-datepicker/locale/de";

export default {
  name: "PostAlertsConfigurationModal",

  props: {
    showModal: Boolean,
    alertsConfigurationId: Number,
  },

  data() {
    return {
      day: null,
      time: moment().format("H:m"),
      recipients: [],
      isEnabled: false,

      allUsers: [],
      areRecipientsLoading: false,
      isLoading: false,
      isRecipientsOpen: false,
    };
  },

  components: { DatePicker, vSelect },

  methods: {
    ...mapActions([
      "getAlertsConfiguration",
      "updateAlertsConfiguration",
      "getAllUsers",
    ]),

    resetData() {
      this.time = moment().format("H:m");
      this.day = null;
      this.recipients = [];
      this.isLoading = this.areRecipientsLoading = this.isEnabled = false;
    },

    hideModal() {
      this.$emit("close");
      this.resetData();
    },

    hideModalManual() {
      this.$refs["post-alerts-configuration-modal"].hide();
      this.hideModal();
    },

    hideWithUpdateModal() {
      this.$refs["post-alerts-configuration-modal"].hide();
      this.$emit("closeUpdate");
      this.resetData();
    },

    closeRecSelect() {
      setTimeout(() => {
        this.isRecipientsOpen = !this.isRecipientsOpen;
      }, 150);
    },

    async fetchAlertsConfiguration() {
      this.isLoading = true;

      try {
        const alertsConfiguration = await this.getAlertsConfiguration(
          this.alertsConfigurationId
        );
        let config = alertsConfiguration.data;
        this.recipients = config.recipients;
        this.isEnabled = config.is_active;
        this.day =
          config.crontab.day_of_week === "*"
            ? null
            : config.crontab.day_of_week;
        if (config.crontab.hour !== "*" && config.crontab.minute !== "*") {
          this.time = `${config.crontab.hour}:${config.crontab.minute}`;
        } else this.time = moment().format("HH:mm");
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        this.hideModalManual();
      }

      this.isLoading = false;
    },

    async fetchUsers() {
      this.areRecipientsLoading = true;

      try {
        const recipients = await this.getAllUsers({});
        this.allUsers = recipients.data;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        this.hideModalManual();
      }

      this.areRecipientsLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;

      let crontab = {
        minute: this.time.split(":")[1],
        hour: this.time.split(":")[0],
        day_of_week: this.day,
        day_of_month: "*",
        month_of_year: "*",
      };

      try {
        const data = {
          crontab,
          recipients: this.recipients,
          is_active: this.isEnabled,
        };
        if (this.alertsConfigurationId) {
          await this.updateAlertsConfiguration({
            id: this.alertsConfigurationId,
            data,
          });
          this.makeToast(
            "success",
            this.$t("alertsConfigurationMsgs.configUpdated")
          );
        }
        this.isLoading = false;
        this.hideWithUpdateModal();
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  computed: {
    ...mapGetters(["getCurrLanguage"]),

    lang() {
      return this.getCurrLanguage;
    },

    allDaysOptions() {
      return [
        { value: 0, text: this.$t("dayNames.sunday") },
        { value: 1, text: this.$t("dayNames.monday") },
        { value: 2, text: this.$t("dayNames.tuesday") },
        { value: 3, text: this.$t("dayNames.wednesday") },
        { value: 4, text: this.$t("dayNames.thursday") },
        { value: 5, text: this.$t("dayNames.friday") },
        { value: 6, text: this.$t("dayNames.saturday") },
      ];
    },
  },

  async mounted() {},

  watch: {
    showModal(value) {
      if (value) {
        if (this.alertsConfigurationId) {
          this.fetchAlertsConfiguration();
        }
        this.fetchUsers();
        this.$refs["post-alerts-configuration-modal"].show();
      }
    },
  },
};
</script>
